import { css } from "@emotion/react";
import { CallToAction, Card, Section } from "../components";
import { StyleShape3 } from "../components/Shapes";
import Theme from "../components/Theme";
import { getAssetImage } from "../core";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;
    ::before {
      ${StyleShape3};
      z-index: ${Theme.zIndex.header + 1};
      background-size: contain;
      width: 800px;
      top: -500px;
      left: -300px;
      transform: rotate(140deg);
    }
  `,
  layout: css`
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-top: 48px;
  `,
  card: css`
    display: flex;
    flex-direction: column;
    padding: 24px;

    > img {
      height: 48px;
      width: 48px;
      margin-bottom: 24px;
    }

    > h6 + div {
      margin-top: 16px;
    }
  `,
};

export interface SectionPlatformInfoProps {
  heading: string;
  content?: string;
  items: Array<{
    heading: string;
    content: string;
    image: string;
  }>;
}

export const SectionPlatformInfo = ({ heading, content, items }: SectionPlatformInfoProps) => (
  <div css={styles.base}>
    <Section marginTop marginBottom>
      {heading && (
        <CallToAction width="60%" appearance="tertiary" alignment="center" heading={heading} content={content} />
      )}
      <div css={styles.layout}>
        {items.map(({ heading, content, image }, index) => (
          <Card key={`i${index}`} sx={styles.card}>
            <img src={getAssetImage(image)} alt={heading} />
            <h6 css={Theme.typography.h6}>{heading}</h6>
            {content && <div css={Theme.typography.body.medium} dangerouslySetInnerHTML={{ __html: content }} />}
          </Card>
        ))}
      </div>
    </Section>
  </div>
);
