import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import { BlockMore } from "../components";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";
import { SectionPlatformDetails } from "../sections/PlatformDetails";
import { SectionPlatformHero } from "../sections/PlatformHero";
import { SectionPlatformInfo } from "../sections/PlatformInfo";

interface PageProps {
  location: WindowLocation;
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
        more?: {
          heading?: string;
          content?: string;
          items: Array<
            CallToActionData & {
              image: string;
            }
          >;
        };
        platform?: {
          heading: string;
          content?: string;
          items: Array<{
            anchor: string;
            heading: string;
            content: string;
            image: string;
            figure: string;
          }>;
        };
        info?: {
          heading: string;
          content?: string;
          items: Array<{
            heading: string;
            content: string;
            image: string;
          }>;
        };
      };
    };
  };
}

export default ({
  location,
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, more, platform, info },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    <SectionPlatformHero {...hero} />
    <main>
      {more && <BlockMore {...more} />}
      {platform && <SectionPlatformDetails {...platform} location={location} />}
      {info && <SectionPlatformInfo {...info} />}
    </main>
  </PageLayout>
);

export const query = graphql`
  query PlatformQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/platform.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
          action {
            text
            href
          }
        }
        more {
          items {
            image
            heading
            content
          }
        }
        platform {
          heading
          content
          items {
            anchor
            heading
            content
            image
            figure
          }
        }
        info {
          heading
          content
          items {
            image
            heading
            content
          }
        }
      }
    }
  }
`;
