import { css } from "@emotion/react";
import { CallToAction, Section } from "../components";
import { StyleShape2 } from "../components/Shapes";
import Theme from "../components/Theme";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;

    ::before {
      ${StyleShape2};
      right: clamp(-600px, calc(-20%), -400px);
      transform: rotate(-45deg);

      ${Theme.mq._sm} {
        bottom: -100px;
      }

      ${Theme.mq.md_} {
        top: -100px;
      }
    }
  `,
  layout: css`
    align-items: center;
    margin-top: 48px;

    ${Theme.mq._sm} {
      display: flex;
    }

    ${Theme.mq.md_} {
      display: grid;
      grid-template-columns: 4fr 3fr;
      grid-gap: 48px;
    }

    ${Theme.mq.lg_} {
      grid-gap: 64px;
    }
  `,
  cta: css`
    ${Theme.mq._md} {
      > div {
        ${Theme.fontSizes.medium};
      }
      > h1 {
        ${Theme.fontSizes.h2};
      }
    }
  `,
  image: css`
    position: relative;

    ${Theme.mq._sm} {
      margin-top: 48px;
      height: 297px;
      width: 100%;
      flex: none;
    }

    ${Theme.mq.md_} {
      height: 519px;
    }

    > img {
      object-fit: contain;
      position: absolute;

      ${Theme.mq._sm} {
        height: 100%;
        width: auto;
      }

      ${Theme.mq.md_} {
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  `,
};

export const SectionPlatformHero = (props: CallToActionData) => {
  return (
    <header css={styles.base}>
      <Section sx={styles.layout}>
        <CallToAction appearance="secondary" {...props} sx={styles.cta} />
        <div css={styles.image}>
          <img
            src="/assets/platform-hero.png"
            alt="The one Hybrid IT Intelligence Platform"
            width={1005}
            height={519}
          />
        </div>
      </Section>
    </header>
  );
};
