import { css } from "@emotion/react";
import { WindowLocation } from "@reach/router";
import { ButtonPane, CallToAction, Section } from "../components";
import Theme, { Anchor } from "../components/Theme";
import { getAssetImage } from "../core";

const styles = {
  layout: css`
    margin-top: 30px;

    display: grid;
    grid-gap: 48px;
    align-items: center;
    grid-auto-flow: row;

    ${Theme.mq.md_} {
      grid-template-columns: minmax(250px, 1fr) 1fr;
    }

    > img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  `,
  selector: css`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 12px;
  `,
};

export interface SectionPlatformDetailsProps {
  heading: string;
  content?: string;
  items: Array<{
    anchor: string;
    heading: string;
    content: string;
    image: string;
    figure: string;
  }>;
  location: WindowLocation;
}

export const SectionPlatformDetails = ({ heading, content, items, location }: SectionPlatformDetailsProps) => {
  const selectedIndex =
    location.hash == null
      ? 0
      : Math.max(
          0,
          items.findIndex(({ anchor }) => location.hash === `#${anchor}`)
        );
  const selectedItem = items[selectedIndex];
  return (
    <Section marginTop marginBottom>
      {heading && <CallToAction width="50%" appearance="h4" heading={heading} content={content} />}
      {items.map(({ anchor }, index) => (
        <a key={`a${index}`} id={anchor} css={Anchor} />
      ))}
      <div css={styles.layout}>
        <div css={styles.selector}>
          {items.map(({ heading, content, image, anchor }, index) => (
            <ButtonPane
              key={`i${index}`}
              image={getAssetImage(image)}
              header={heading}
              href={`/platform#${anchor}`}
              checked={selectedIndex === index}>
              {content}
            </ButtonPane>
          ))}
        </div>
        <img src={getAssetImage(selectedItem.figure)} alt={selectedItem.heading} />
      </div>
    </Section>
  );
};
